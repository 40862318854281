import { graphql } from 'gatsby';
import React from 'react';
import axios from 'axios';
import Icon from '../components/Icon';

import MagneticButton from '../components/MagneticButton';
import GiveContentTrans from '../components/GiveContentTrans';
import '../styles/layout/contact.scss';
import SEO from '../components/seo';

import Layout from '../components/Layout';

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    // HAcky solution to determine language in class based page
    let pageLanguage = 'et';
    if (
      props.path === '/en/contact/' ||
      props.path === 'en/contact/' ||
      props.path === '/en/contact' ||
      props.path === 'en/contact'
    ) {
      pageLanguage = 'en';
    }

    this.state = {
      submitting: false,

      fieldContact: '',
      fieldCompany: '',
      fieldMessage: '',
      fieldTripWire: '',

      fieldErrors: [],

      messageError: '',
      messageSuccess: '',

      uiLanguage: pageLanguage,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleTripWireChange = this.handleTripWireChange.bind(this);
  }

  handleContactChange(event) {
    this.setState({
      fieldContact: event.target.value,
    });
  }

  handleCompanyChange(event) {
    this.setState({
      fieldCompany: event.target.value,
    });
  }

  handleMessageChange(event) {
    this.setState({
      fieldMessage: event.target.value,
    });
  }

  handleTripWireChange(event) {
    this.setState({
      fieldTripWire: event.target.value,
    });
  }

  handleSubmit() {
    this.setState({
      submitting: true,
      messageError: '',
      messageSuccess: '',
    });
    // language: I18nContext.language,

    const params = JSON.stringify({
      accessToken: 'givp2fj85jd7d04mfd07ka784sk9gj3k74fuf7',
      action: 'requestContact',
      uiLanguage: this.state.uiLanguage,
      contactCompany: this.state.fieldCompany,
      contact: this.state.fieldContact,
      message: this.state.fieldMessage,
      tripwire: this.state.fieldTripWire,
    });

    let promise = null;
    const that = this;

    try {
      promise = axios
        .post('https://give.ee/give-api/', params, {
          headers: {
            'content-type': 'text/plain',
          },
        })
        .then((result) => {
          if (result.status == 200) {
            if (
              typeof result.data.response.code !== 'undefined' &&
              result.data.response.code == 200
            ) {
              that.requestSuccess();
            } else if (typeof result.data.response.code !== 'undefined') {
              let extractedFieldErrors = [];
              if (
                typeof result.data.data !== 'undefined' &&
                typeof result.data.data.errorFields !== 'undefined'
              ) {
                extractedFieldErrors = result.data.data.errorFields;
              }
              that.requestFailed(
                result.data.response.message,
                extractedFieldErrors
              );
            } else {
              that.requestFailed('Sõnumi saatmine ebaõnnestus', []);
            }
          } else {
            that.requestFailed('Sõnumi saatmine ebaõnnestus', []);
          }
        })
        .catch(function (error) {
          that.requestFailed(error, []);
        });
    } catch (error) {
      that.requestFailed(error, []);
    }
  }

  requestFailed(generalError, fieldErrors) {
    const newState = this.setState({
      submitting: false,
      messageError: generalError,
      fieldErrors,
    });
  }

  requestSuccess() {
    this.setState({
      submitting: false,
      messageError: '',
      // 'fieldContact' : '',
      // 'fieldCompany' : '',
      fieldMessage: '',

      fieldErrors: [],

      messageSuccess: 'Sõnum edastatud!',
    });
  }

  render() {
    let formClasses = 'contact-form';
    if (this.state.submitting) {
      formClasses += ' form-loading';
    }

    let fieldContactClasses = 'form-field';
    let fieldCompanyClasses = 'form-field';
    let fieldMessageClasses = 'form-field';
    let fieldTripWireClasses = 'form-field form-field__meepotike'; // honeybot- not for humans to fill in. Will be removed from screen
    let fieldContactError = '';
    let fieldCompanyError = '';
    let fieldMessageError = '';
    let fieldTripWireError = '';

    this.state.fieldErrors.forEach((item) => {
      if (item.key == 'contact') {
        fieldContactClasses += ' form-field__error';
        fieldContactError = item.error;
      }
      if (item.key == 'company') {
        fieldCompanyClasses += ' form-field__error';
        fieldCompanyError = item.error;
      }
      if (item.key == 'message') {
        fieldMessageClasses += ' form-field__error';
        fieldMessageError = item.error;
      }
      if (item.key == 'message') {
        fieldTripWireClasses += ' form-field__error';
        fieldTripWireError = item.error;
      }
    });

    const withFocus = ' use-focus-within';

    if (this.state.fieldContact === '') {
      fieldContactClasses += withFocus;
    }

    if (this.state.fieldCompany === '') {
      fieldCompanyClasses += withFocus;
    }

    if (this.state.fieldMessage === '') {
      fieldMessageClasses += withFocus;
    }

    return (
      <Layout>
      <main className="contact-page outer-grid">
        
        <GiveContentTrans language="et">
          <SEO
            title="Give digiagentuuri kontaktid"
            description="Vajad abi kodulehe tegemise, logo, brändingu või UI/UX disainiga? Võta kohe ühendust ja pakkume kiired ning head lahendused."
            image="/pagecover.jpg"
            structuredData={{
              "@type": "WebPage",
              "name": "Give digiagentuuri kontaktid",
              "description": "Vajad abi kodulehe tegemise, logo, brändingu või UI/UX disainiga? Võta kohe ühendust ja pakkume kiired ning head lahendused.",
              "url": "https://give.ee/kontakt/", 
              "inLanguage": "et"
            }}
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <SEO
            title="Give digital agency contacts"
            description="Need help with website creation, branding or UI/UX design? Contact us and explain your needs. We will offer fast and good solutions."
            image="/pagecover-en-default.jpg"
            structuredData={{
              "@type": "WebPage",
              "name": "Give digital agency contacts",
              "description": "Need help with website creation, branding or UI/UX design? Contact us and explain your needs. We will offer fast and good solutions.",
              "url": "https://give.ee/en/contact/", 
              "inLanguage": "et"
            }}
          />
        </GiveContentTrans>
        <section className="hero">
          <GiveContentTrans language="et">
            <h1>Lahe! Tundub, et Teil on huvi? </h1>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h1>Cool! Lets connect? </h1>
          </GiveContentTrans>
          <div className="content" />
        </section>
        <section className="description">
          <div className="page-copy">
            <GiveContentTrans language="et">
              <p>
                Kui soovite meie digiagentuuri abiga midagi vinget luua, siis kirjutage
                detailsemalt mida ning mis ajaks on soov teha.
              </p>
              <h3>Digiagentuuri kontaktid</h3>
              <a href="mailto:studio@give.ee">studio@give.ee</a> <br />
              <a href="tel:+3725533913">+372 55 33 913</a>
              <h3>Jälgi meid sotsiaalmeedias</h3>
              <a href="https://www.linkedin.com/company/give-digital-agency/">LinkedIn</a>
              <br />
              <a href="https://dribbble.com/Weekendvisuals">Dribbble</a>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                If you wish to create something awesome with our digital agency, please provide
                details on what you would like to achieve and the timeline for
                completion. This will allow us to save time right from the
                start.
              </p>
              <h3>Contact us</h3>
              <a href="mailto:studio@give.ee">studio@give.ee</a> <br />
              <a href="tel:+3725533913">+372 55 33 913</a>
              <h3>Follow us</h3>
              <a href="https://www.linkedin.com/company/give-digital-agency/">LinkedIn</a>
              <br />
              <a href="https://dribbble.com/Weekendvisuals">Dribbble</a>
            </GiveContentTrans>
          </div>
          <div className={formClasses}>
            <form method="post">
              <GiveContentTrans language="et">
                <h3>Saada sõnum</h3>
              </GiveContentTrans>
              <GiveContentTrans language="en">
                <h3>Send a message</h3>
              </GiveContentTrans>

              {this.state.messageError && (
                <div className="feedback-error">{this.state.messageError}</div>
              )}

              {this.state.messageSuccess && (
                <div className="feedback-success">
                  {this.state.messageSuccess}
                </div>
              )}

              <div className={fieldContactClasses}>
                <label className="contact-label" htmlFor="email">
                  <GiveContentTrans language="et">
                    E-mail või telefon
                  </GiveContentTrans>
                  <GiveContentTrans language="en">
                    E-mail or phone
                  </GiveContentTrans>
                </label>

                <input
                  type="email"
                  name="email"
                  value={this.state.fieldContact}
                  onChange={this.handleContactChange}
                />
                {fieldContactError != '' && (
                  <div className="feedback-field-error">
                    {fieldContactError}
                  </div>
                )}
              </div>
              <div className={fieldCompanyClasses}>
                <label className="contact-label" htmlFor="name">
                  <GiveContentTrans language="et">
                    Ettevõtte nimi
                  </GiveContentTrans>
                  <GiveContentTrans language="en">
                    Company name
                  </GiveContentTrans>
                </label>

                <input
                  type="text"
                  name="name"
                  value={this.state.fieldCompany}
                  onChange={this.handleCompanyChange}
                />
                {fieldCompanyError != '' && (
                  <div className="feedback-field-error">
                    {fieldCompanyError}
                  </div>
                )}
              </div>

              <div className={fieldMessageClasses}>
                <label className="contact-label" htmlFor="name">
                  <GiveContentTrans language="et">Sõnum</GiveContentTrans>
                  <GiveContentTrans language="en">Message</GiveContentTrans>
                </label>

                <textarea
                  rows="5"
                  value={this.state.fieldMessage}
                  onChange={this.handleMessageChange}
                />
                {fieldMessageError != '' && (
                  <div className="feedback-field-error">
                    {fieldMessageError}
                  </div>
                )}
              </div>

              <div className={fieldTripWireClasses}>
                <label className="contact-label" htmlFor="name">
                  Aadress
                </label>
                {fieldTripWireError != '' && (
                  <div className="feedback-field-error">
                    {fieldTripWireError}
                  </div>
                )}
                <input
                  value={this.state.fieldTripWire}
                  onChange={this.handleTripWireChange}
                />
              </div>

              <GiveContentTrans language="et">
                <MagneticButton
                  addionalClasses="cta cta-invert"
                  buttonLabel="Saada"
                  magneticDistance="150"
                  magneticSpeed="0.4"
                  onClick={this.handleSubmit}
                />
              </GiveContentTrans>
              <GiveContentTrans language="en">
                <MagneticButton
                  addionalClasses="cta cta-invert"
                  buttonLabel="Send"
                  magneticDistance="150"
                  magneticSpeed="0.4"
                  onClick={this.handleSubmit}
                />
              </GiveContentTrans>

              {this.state.submitting && (
                <div className="feedback-loading">
                  <GiveContentTrans language="et">Saadan ...</GiveContentTrans>
                  <GiveContentTrans language="en">Sending ...</GiveContentTrans>
                </div>
              )}
            </form>
          </div>
        </section>
      </main>
      </Layout>
    );
  }
}

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
